import "./logosection.css"
import logo from '../../../images/headerimages/logo.svg';

const LogoSection = () => {
  return(
    <div className="logo-section">
      <img src={logo} alt="logo-of-the-site" />
      <p className="name-of-author">Thymios Arvanitis</p>
      <p className="job-of-author">Nutritionist</p>
    </div>
  )
}

export default LogoSection