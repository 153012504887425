import "./cookiesPolicy.css"

const CookiesPolicy = () =>{
  return(
    <div className="cookies-policy">
      <h1>Cookies Policy for Nutrition Arvanitis</h1>
      <h3>Last Updated: 2024</h3>
      <h4 className="intro">Introduction</h4>
      <p>Nutrition Arvanitis uses cookies and similar technologies to enhance your experience while using our website. This Cookies Policy provides detailed information about how and why we use cookies and the choices you have.</p>
    <h3>What are Cookies?</h3>
    <p>Cookies are small text files that are placed on your device by websites that you visit. They are widely used to make websites work more efficiently, as well as to provide information to the owners of the site.</p>
    <h3>Use of Cookies</h3>
    <p className="list-label">We use cookies to:</p>
    <ul>
      <li><b>Improve Website Performance:</b> Cookies help us understand how our website is being used and how we can improve the user experience.</li>
      <li><b>Enhance User Experience:</b> They remember your preferences and settings, making your interactions with our site smoother.</li>
      <li><b>Analytics and Customization:</b> We use analytics cookies to gather aggregated data on website traffic and interaction, which helps us offer better site experiences and tools in the future.</li>
    </ul>
      <p className="list-label">Types of Cookies We Use:</p>
      <ul>
        <li><b>Essential Cookies: </b>These are necessary for the website to function and cannot be switched off in our systems. They are usually set in response to actions made by you which amount to a request for services.</li>
        <li><b>Performance Cookies: </b>These allow us to count visits and traffic sources so we can measure and improve the performance of our site.</li>
        <li><b>Functionality Cookies: </b>These are used to recognize you when you return to our website, enabling us to personalize our content for you.</li>
      </ul>
      <h3>Managing Cookies</h3>
      <p>You have the right to decide whether to accept or reject cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. Please be aware that if cookies are disabled, not all features of the site may operate as intended.</p>
      <h3>Changes to the Cookies Policy</h3>
      <p>We may update this Cookies Policy from time to time. We encourage you to periodically review this policy for the latest information on our cookies practices.</p>
      <h3>Contact Us</h3>
      <p className="last">If you have any questions about our use of cookies or other technologies, please email us at <b className="green">thymiosarvanitis@e-nutritionist.com</b> or call us at <b className="green">+34613497305</b>.</p>
    </div>
  )
}

export default CookiesPolicy